import { argbFromHex, themeFromSourceColor, applyTheme } from "@material/material-color-utilities";

export { Navbar } from "./components/nav-bar/nav-bar.js";
export { Sidebar } from "./components/sidebar/sidebar.js";

export { Button } from "./components/button/button.js";
export { Q1Router } from "./router/Q1Router.js";
export { TextField } from "./components/text-field/text-field.js";
export { Icon } from "./components/icon/icon.js";
export { IconButton } from "./components/icon-button/icon-button.js";
export { Select } from "./components/select/select.js";
export { Radio } from "./components/radio/radio.js";
export { Menu } from "./components/menu/menu.js";
export { Switch } from "./components/switch/switch.js";


// Updated Components

export {DpButton} from "./components/button/dp-button.js";
export {DpCheckbox} from "./components/checkbox/dp-checkbox.js";
export {Container} from './components/container/container.js';
export {DateComponent} from "./components/date/dp-date.js";
export {DropdownComponent} from "./components/dropdown/dp-dropdown.js";
export {DpEmail} from "./components/email/dp-email.js";
export {DpNumber} from "./components/number/dp-number.js";
export {DpPassword} from "./components/password/dp-password.js";
export {DpRadio} from "./components/radio/dp-radio.js";
export {DpReset} from "./components/reset/dp-reset.js";
export {DpText} from "./components/text/dp-text.js";
export {DpTextarea} from "./components/textarea/dp-textarea.js";


export {ImageSlider} from "./components/imageslider/ImageSlider.js";
export {SignupComponent} from "./views/signup/signup-component.js";
export {BaseView} from "./views/base-view/base-view.js";
export {LoginComponent} from "./views/login/login-component.js";
export {ForgotPasswordComponent} from "./views/forgot-password/forgot-password-component.js";
export {CreatePasswordComponent} from "./views/create-password/create-password-component.js";



export {HeaderComponent} from "./views/header-view/header-component.js";
export {HomeComponent} from "./views/home-view/home-component.js";
export {DashboardComponent} from "./views/dashboard/dashboard-component.js";
export {ManageAccount} from "./views/manage-account/manage-account.js";
export {ManageOrgAccount} from "./views/my-org-account/my-org-account.js";


export {AuthView} from "./views/auth-view/auth-view.js";

export {LandingComponent} from "./views/landing-view/landing-component.js";
export {FooterComponent} from "./views/footer-view/footer-component.js";
export {DropdownMenu} from "./views/dropdown/dropdown-menu.js";







// Configure the colors used across Q1 App Suite
// Get the theme from a hex color
const theme = themeFromSourceColor(argbFromHex('#ECBB1F'), [
    {
      name: "quorum-one-theme",
      value: argbFromHex("#ff0000"),
      blend: true,
    },
  ]);
  
// Print out the theme as JSON
// console.log(JSON.stringify(theme, null, 2));

// Apply the theme to the body by updating custom properties for material tokens
applyTheme(theme, {target: document.body});
