export const defaultlinkConfig = {
  applicationLinks: {
    'Home': '/home',
    'Login': '/login',
    'Signup': '/signup',
    'survey': '/survey',
    'Forgot Password': '/forgot-password',
    'My Account':'/my-account'
  },
  externalLinks: {
    'Google': 'http://google.com',
    'The Verge': 'https://theverge.com'
  }
};

export const defaultRouterConfig = {
  routes: {
    '/': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'landing.html', active: false },
    '/login': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'login.html', active: false },
    '/signup': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'signup.html', active: false },
    '/forgot-password': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'forgot-password.html', active: false },
    '/survey': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'survey.html', active: false },
    '/my-account': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'my-account.html', active: false },
    '/manage-org': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'manage-org-account.html', active: false },
    '/my-survey': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'my-survey.html', active: false },
    
    
    '*': { title: 'Not Found', content: 'not-found.html', active: false }
  }
};