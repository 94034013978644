import { LitElement, html, css } from 'lit';
import { customElement ,property} from 'lit/decorators.js';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase-config';

@customElement('dropdown-menu')
export class DropdownMenu extends LitElement {
  @property({ type: Object }) details = {};

  static styles = css`
    :host {
      display: block;
      box-sizing: border-box;
    }
    .dropdown {
      position: relative;
      display: inline-block;
    }
    .dropdown-content {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      background-color: white;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      border-radius: 4px;
    }
    .dropdown-content a {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      color: #755b00;
      font-size: 12px;
    }
.dropdown-content span {
        padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #755b00;
    font-size: 12px;
        cursor: pointer;
}
    .dropdown-content a:hover {
      background-color: #ddd;
    }
    .dropdown.show .dropdown-content {
      display: block;
    }
    .icon {
      cursor: pointer;
      font-size: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
    }
    .icon img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  `;

  constructor() {
    super();
    this.showMenu = false;
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.requestUpdate();
  }
  
  userorgaccount() {
    // Add your login logic here
    // Router.go("/signup");
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/manage-org" },
        bubbles: true,
        composed: true,
      })
    );
  }
  myaccount() {
    // Add your login logic here
    // Router.go("/signup");
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/my-account" },
        bubbles: true,
        composed: true,
      })
    );
  }

  mySurvey() {
    // Add your login logic here
    // Router.go("/signup");
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/my-survey" },
        bubbles: true,
        composed: true,
      })
    );
  }

  

  


  logout() {
    signOut(auth)
      .then(() => {
        this.dispatchEvent(
          new CustomEvent('navigate-to-route', {
            detail: { routePath: '/' },
            bubbles: true,
            composed: true,
          })
        );
      })
      .catch((error) => {
        console.error('Sign out error', error);
      });
  }

  render() {
    return html`
      <div class="dropdown ${this.showMenu ? 'show' : ''}">
        <div class="icon" @click=${this.toggleMenu}>
          <img src="../assets/man-menu.png" alt="User Icon" />
        </div>
        <div class="dropdown-content">
         <a class="email" href="#" @click=${this.details.email}>${this.details.email}</a>
           <span class="terms" @click=${this.myaccount}>Manage User Account</span>
           
          <span class="terms" @click=${this.userorgaccount}>Manage Organization Account</span>
           <span class="terms" @click=${this.mySurvey}>Survey</span>
          <a class="logout" href="#" @click=${this.logout}>Logout</a>
        </div>
      </div>
    `;
  }
}
