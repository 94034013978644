import {LitElement} from 'lit';
import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import {baseStyles} from '../../styles/base-styles'


const firebaseConfig = {
  apiKey: 'AIzaSyAcCwlK28uu9atHlm-6JGAbG15V_1xh-3k',
  authDomain: 'datapoint-44397.firebaseapp.com',
  projectId: 'datapoint-44397',
  storageBucket: 'datapoint-44397.appspot.com',
  messagingSenderId: '109451003258',
  appId: '1:109451003258:web:fa68a25cad9fbe7815c2c3',
  measurementId: 'G-348TPC4KGJ',
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

//connectAuthEmulator(auth, 'http://127.0.0.1:9099');

export class BaseView extends LitElement {
  static properties = {
    isSignedIn: {type: Boolean},
    currentUser: {type: Object},
  };

  static styles = [
    baseStyles,
  ];

  firebaseUiInitialized = false;

  initApp() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log('SIGNED IN');

        user.getIdToken().then((accessToken) => {
          console.log('resolving');
          this.isSignedIn = true;
          this.currentUser = user;
        });
      } else {
        // User is signed out.
        console.log('SIGNED OUT');
        this.isSignedIn = false;
      }
    });
  }

  constructor() {
    super();

    this.initApp();
  }
}
