import Events from './Events.js';
import { defaultRouterConfig } from '../defaultConfig.js';

const ROUTER_TYPES = {
  hash: 'hash',
  history: 'history'
};

function defer(x) {
  setTimeout(() => x(), 10);
}

// Router for handling Single Page Applications using Lit.js
// Default route needs to be called after Router creation:
// (new Router(config)).setRoute("/")
export default class Router {
  constructor(options = {}) {
    this.events = new Events(this);

    // Merge provided options with defaults
    this.options = {
      type: ROUTER_TYPES.history, // default routing type
      routes: defaultRouterConfig.routes,   // default routes
      ...options               // overrides from provided options
    };
  }

  listen() {
    this.routeHash = Object.keys(this.options.routes);

    if (!this.routeHash.includes('/')) {
      throw new TypeError('No home route found');
    }

    if (this.isHashRouter) {
      window.addEventListener('hashchange', this._hashChanged.bind(this));
    } else {
      let href = document.location.origin;

      if (this._findRoute(document.location.pathname)) {
        href += document.location.pathname;
      }

      window.addEventListener('popstate', this._triggerPopState.bind(this));
    }

    // Return the Router instance to allow method chaining
    return this;
  }

  _hashChanged() {
    this._tryNav(document.location.hash.substring(1));
  }

  _triggerPopState(e) {
    // Check if e.state is not null
    if (e.state) {
      this._triggerRouteChange(e.state.path, e.target.location.href);
    } else {
      // Handle the case where e.state is null, possibly by using window.location
      // This could be a default route or a logic to determine the route based on the URL
      const path = window.location.pathname;
      this._triggerRouteChange(path, window.location.href);
    }
  }  

  _triggerRouteChange(path, url) {
    // Get the route configuration for the given path, or the default '*' if not found
    const routeConfig = this.options.routes[path] || this.options.routes['*'];

    // Update the document title
    document.title = routeConfig.title;

    // Trigger the route change event
    this.events.trigger('route', {
        route: routeConfig,
        // Use the original path for a valid route, otherwise use "/not-found"
        path: this.options.routes[path] ? path : '/not-found',
        url: url
    });
}

  _findRoute(url) {
    const urlMatch = url.match(/(?<url>[A-Za-z_0-9.]*)/gm)[0];
    const test = `/${urlMatch}`;
    return this.routeHash.includes(url) ? url : '*';
  }

  _tryNav(href) {
    const url = this._createUrl(href);

    if (url && url.protocol.startsWith('http')) {
      const routePath = this._findRoute(url.pathname);

      if (routePath && this.options.routes[routePath]) {
        this._triggerRouteChange(url.pathname, url);
        return true;
      }
    }
    return false;
  }

  _createUrl(href) {
    let newHref = href;

    if (this.isHashRouter && href.startsWith('#')) {
      newHref = href.substr(1);
    }

    try {
      return new URL(newHref, document.location.origin);
    } catch (e) {
      return null;
    }
  }

  setRoute(path) {
    // TODO do I need to remove this and just route to invalid instead?
    // Check if the path exists in the defined routes
    if (!this._findRoute(path)) {
      throw new TypeError('Invalid route');
    }

    // Update the browser's history and URL
    if (this.options.type === 'history' && window.location.pathname !== path) {
      history.pushState({ path: path }, '', path);
    } else {
      // If it's a hash router, update the hash part of the URL
      window.location.hash = path;
    }

    // Load the new route's content
    this._tryNav(path);
  }

  // Event handling methods
  on(eventName, func) {
    this.events.host.on(eventName, func);
  }

  off(eventName, func) {
    this.events.host.removeEventListener(eventName, func);
  }

  get isHashRouter() {
    return this.options.type === ROUTER_TYPES.hash;
  }
}
