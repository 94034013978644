import { LitElement, html, css } from 'lit';
import axios from 'axios';
import { getAuth, signInWithEmailAndPassword ,sendEmailVerification,onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from '../../firebase-config';
import '../../components/loadingSpinner/loading-spinner.js'; // Import the spinner component

export class LoginComponent extends LitElement {
  static styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh;
      
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      max-width: 400px;
      padding: 20px;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin-top: 20px; /* Adjusted margin for top */
    }

    h2 {
      margin-bottom: 20px;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .login-form input {
      margin-bottom: 15px;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      
      background-color: rgb(240, 222, 223);
    }

    .login-form button {
      padding: 10px;
      font-size: 16px;
      background-color: #8e4953;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 20px;
      margin-bottom: 10px;
    }

    .login-form button:hover {
      background-color: #7c4049;
    }

    .error {
      color: red;
      margin-top: 10px;
    }

    .success {
      color: green;
      margin-top: 10px;
    }

    .text-link {
      color: #8e4953;
      font-size: 16px;
      margin-top: 10px;
       margin-bottom: 10px;
      cursor: pointer;
    }
       .text-link.center {
      text-align:center;
    }
       .button {
      position: relative;
     
      font-size: 16px;
      cursor: pointer;
    }

    .button[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .loader-container {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    .hidden {
      display: none;
    }

  `;

  static properties = {
    email: { type: String },
    password: { type: String },
    errorMessage: { type: String },
    successMessage: { type: String },
    isLoading: { type: Boolean }
  };

  constructor() {
    super();
    this.email = '';
    this.password = '';
    this.errorMessage = '';
    this.successMessage = '';
    this.isLoading = false;
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this[name] = value;
  }

  navigateToForgotPassword() {
    this.dispatchEvent(new CustomEvent('navigate-to-route', {
      detail: { routePath: '/forgot-password' },
      bubbles: true,
      composed: true,
    }));
  }

  navigateToSignUP() {
    this.dispatchEvent(new CustomEvent('navigate-to-route', {
      detail: { routePath: '/signup' },
      bubbles: true,
      composed: true,
    }));
  }

  async generateverificationlink (user){
    try {

      await sendEmailVerification(user);
  } catch (error) {
  }  
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.errorMessage = ''; // Clear any previous error message
    this.successMessage = ''; // Clear any previous success message
    this.isLoading = true; // Start loading

    try {
      const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
      const user = userCredential.user;
      // this.successMessage = 'Login successful!';
      
     /// await sendEmailVerification(user);

     //console.log(user.toJSON());

      if (user.emailVerified == true) {
        this.successMessage = 'Login successful! Welcome.';
        this.errorMessage = ''; // Clear any error message

        this.isLoading = false; // Start loading
        this.dispatchEvent(new CustomEvent('navigate-to-route', {
          detail: { routePath: '/dashboard' },
          bubbles: true,
          composed: true,
        }));

      } else {
        this.isLoading = false; // Start loading
        this.successMessage = '';
        this.errorMessage = `Email not verified. Please verify your email before logging in.`;
        signOut(auth)
        .then(() => {
          console.log("User signed out");
        })
        .catch((error) => {
          console.error("Sign out error", error);
        });

      }


     
    } catch (error) {

      if (error.code === 'auth/invalid-credential') {
        this.errorMessage = 'Invalid email or password. Please try again.';
        this.isLoading = false;
      } else if (error.code === 'auth/user-not-found') {
        this.errorMessage = 'No account found with this email.';
        this.isLoading = false;
      } else {
        this.errorMessage = 'Login failed: Internal server error. Please try again later.';
        this.isLoading = false;
      }
     
    }
  }

  render() {
    return html`
      <div class="main">
        <h2>Login</h2>
        <form class="login-form" @submit="${this.handleSubmit}">
          <input
            type="email"
            name="email"
            .value="${this.email}"
            @input="${this.handleInputChange}"
            placeholder="Email"
            required
          />
          <input
            type="password"
            name="password"
            .value="${this.password}"
            @input="${this.handleInputChange}"
            placeholder="Password"
            required
          />
          <a class="text-link" @click="${this.navigateToForgotPassword}">Forgot Your Password?</a>
          <button type="submit" class="button" ?disabled="${this.isLoading}">
            ${this.isLoading ? 'Logging...' : 'Login'}
            <span class="loader-container ${this.isLoading ? '' : 'hidden'}">
              <loading-spinner></loading-spinner>
            </span>
          </button>


          ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}
          ${this.successMessage ? html`<p class="success">${this.successMessage}</p>` : ''}

          <a class="text-link center" @click="${this.navigateToSignUP}">New User ? SignUp </a>
        </form>
      </div>
    `;
  }
}

customElements.define('login-component', LoginComponent);
