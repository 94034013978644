export const routerConfig = {
  routes: {
      "/": { title: 'Home', content: 'home.html', active: false },
      "/dashboard": { title: 'Dashboard', content: 'dashboard.html', active: false },
      "/my-account": { title: 'My Account', content: 'my-account.html', active: false },
      "/manage-org": { title: 'My Account', content: 'manage-org-account.html', active: false },
      '/my-survey': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'my-survey.html', active: false },
      "/survey": { title: 'Survey', content: 'survey.html', active: false },
      "/help": { title: 'Help', content: 'help.html', active: false },
      "/privacy": { title: 'Privacy', content: 'privacy.html', active: false },
      "/terms": { title: 'Terms', content: 'terms.html', active: false },
      "/signup": { title: 'Sign Up', content: 'signup.html', active: false },
      "/login": { title: 'Log In', content: 'login.html', active: false },
      '/forgot-password': { title: 'Forgot Password', content: 'forgot-password.html', active: false },
      '*': { title: 'Not Found', content: 'not-found.html', active: false },
     
  }
};