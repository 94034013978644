import { LitElement, html, css } from 'lit';
import { auth } from '../../firebase-config'; // Import your Firebase config
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { customElement, property } from 'lit/decorators.js';

@customElement('manage-org-account')
export class ManageOrgAccount extends LitElement {
  @property({ type: Object }) user = null;
  @property({ type: Object }) organizationDetails = {
    name: 'Example Org',
    image: '',  // URL of the organization image
    members: [
      { name: 'John Doe', email: 'john@example.com' },
      { name: 'Jane Smith', email: 'jane@example.com' },
    ],
  };
  @property({ type: Object }) isEditing = {
    name: false,
    image: false,
    memberIndex: null, // Track which member is being edited
  };
  @property({ type: String }) newOrganizationImage = '';
  @property({ type: String }) tempMemberName = ''; // Temp name for the editing member

  static styles = css`
    .container {
      padding: 2em;
      max-width: 800px;
      margin: auto;
    }
    h2 {
      text-align: center;
    }
    .profile-section, .organization-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5em;
    }
    .organization-image {
      width: 150px;
      height: 150px;
      border-radius: 8px;
      background-color: #ccc;
      background-size: cover;
      background-position: center;
      border: 3px solid #007bff;
      position: relative;
      cursor: pointer;
    }
    .upload-label {
      background-color: #8e4953;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      margin-top: 10px;
      display: inline-block;
    }
    input[type="file"] {
      display: none;
    }
    input {
      width: 100%;
      padding: 8px;
      margin-left: 1em;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
    .details-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      border: 1px solid #ddd;
      margin-bottom: 10px;
      border-radius: 5px;
      position: relative;
    }
    .label {
      font-weight: bold;
    }
    .value {
      margin-left: 1em;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .edit-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 15px;
    }
    .button {
      padding: 8px 16px;
      font-size: 16px;
      background-color: #8e4953;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }
    .cancel-button {
      background-color: #8e4953;
    }
    .danger-zone {
      margin-top: 2em;
      text-align: center;
      border-top: 2px solid red;
      padding-top: 1em;
    }
    .danger-button {
      background-color: #8e4953;
      padding: 8px 16px;
      color: white;
      cursor: pointer;
      border-radius: 5px;
    }
  `;

  constructor() {
    super();
    this.checkAuth();
  }

  checkAuth() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.requestUpdate();
      } else {
        this.user = null;
        this.requestUpdate();
      }
    });
  }

  toggleEdit(field, index = null) {
    if (field === 'membername') {
      this.isEditing = { ...this.isEditing, memberIndex: index };
      this.tempMemberName = this.organizationDetails.members[index].name; // Save current member name to temp
    } else {
      this.isEditing = { ...this.isEditing, [field]: !this.isEditing[field] };
    }
    this.requestUpdate();
  }

  handleInputChange(e, field, index = null) {
    if (field === 'membername' && index !== null) {
      this.tempMemberName = e.target.value; // Update temporary name
    } else {
      this.organizationDetails = { ...this.organizationDetails, [field]: e.target.value };
    }
  }

  handleImageUpload(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.newOrganizationImage = reader.result;  // Store the base64 image
        this.isEditing.image = true;  // Set to editing mode
        this.requestUpdate();
      };
      reader.readAsDataURL(file);
    }
  }

  saveChanges(field) {
    if (field === 'image') {
      this.organizationDetails.image = this.newOrganizationImage;  // Save the uploaded image
    } else if (field === 'membername' && this.isEditing.memberIndex !== null) {
      this.organizationDetails.members[this.isEditing.memberIndex].name = this.tempMemberName; // Update member name
    }
    this.isEditing = { ...this.isEditing, [field]: false, memberIndex: null };  // Exit edit mode
    this.tempMemberName = ''; // Clear temporary member name
    this.requestUpdate();
  }

  cancelEdit(field) {
    if (field === 'membername' && this.isEditing.memberIndex !== null) {
      this.tempMemberName = this.organizationDetails.members[this.isEditing.memberIndex].name; // Reset to original name
    }
    this.isEditing = { ...this.isEditing, [field]: false, memberIndex: null };  // Exit edit mode
    this.requestUpdate();
  }

  deleteMember(index) {
    this.organizationDetails.members.splice(index, 1);  // Remove member
    this.isEditing = { ...this.isEditing, memberIndex: null };  // Clear editing state
    this.requestUpdate();
  }

  async logout() {
    try {
      await signOut(auth);
      console.log('User signed out');
      this.dispatchEvent(new CustomEvent('navigate-to-route', {
        detail: { routePath: '/' },
        bubbles: true,
        composed: true,
      }));
    } catch (error) {
      console.error('Sign out error', error);
    }
  }

  render() {
    return html`
      <div class="container">
        ${this.user
          ? html`
              <h2>Organization Details</h2>
              
              <!-- Organization Image Section -->
              <div class="organization-section">
                <div class="organization-image" style="background-image: url(${this.organizationDetails.image || '../assets/org-placeholder.png'});"></div>
                
                <!-- Button to trigger file upload -->
                <label class="upload-label" @click="${() => this.shadowRoot.querySelector('input[type=file]').click()}">
                  Add an organization photo
                </label>
                <input type="file" accept="image/*" @change="${this.handleImageUpload}" />

                ${this.isEditing.image
                  ? html`
                      <div class="buttons">
                        <button class="cancel-button button" @click="${() => this.toggleEdit('image')}">Cancel</button>
                        <button class="button" @click="${() => this.saveChanges('image')}">Save Changes</button>
                      </div>
                    `
                  : ''}
              </div>

              <!-- Organization Name Row -->
              <div class="details-row">
                <span class="label">Organization Name:</span>
                ${this.isEditing.name
                  ? html`<input type="text" .value="${this.organizationDetails.name}" @input="${(e) => this.handleInputChange(e, 'name')}" />`
                  : html`<span class="value">${this.organizationDetails.name}</span>`}
                <img src="../assets/icons/pen.png" alt="Edit" class="edit-icon" @click="${() => this.toggleEdit('name')}" />
                ${this.isEditing.name
                  ? html`
                      <div class="buttons">
                        <button class="cancel-button button" @click="${() => this.toggleEdit('name')}">Cancel</button>
                        <button class="button" @click="${() => this.saveChanges('name')}">Save Changes</button>
                      </div>
                    `
                  : ''}
              </div>
            
              <!-- Members Section -->
              <div class="details-row">
                <span class="label">Organization Members:</span>
                <button class="button" @click="${() => console.log('Invite Members')}">Invite Members</button>
              </div>
              ${this.organizationDetails.members.map((member, index) => html`
                <div class="details-row">
                  ${this.isEditing.memberIndex === index
                    ? html`<input type="text" .value="${this.tempMemberName}" @input="${(e) => this.handleInputChange(e, 'membername', index)}" />`
                    : html`<span class="value">${member.name}</span>`}
                  <img src="../assets/icons/pen.png" alt="Edit" class="edit-icon" @click="${() => this.toggleEdit('membername', index)}" />
                  &nbsp; &nbsp;  <img src="../assets/icons/delete.png" alt="Edit" class="edit-icon" @click="${() => this.deleteMember(index)}" />
                </div>
                ${this.isEditing.memberIndex === index
                  ? html`
                      <div class="buttons">
                        <button class="cancel-button button" @click="${() => this.cancelEdit('membername')}">Cancel</button>
                        <button class="button" @click="${() => this.saveChanges('membername')}">Save Changes</button>
                      </div>
                    `
                  : ''}
              `)}

              <!-- Danger Zone Section -->
              <div class="danger-zone">
                <button class="danger-button" @click="${() => console.log('Delete Organization')}">Delete Organization</button>
              </div>

              <!-- Logout Button -->
              <button class="button" @click="${this.logout}">Logout</button>
            `
          : html`<p>You are not logged in. Please <a href="/login">login</a> to manage your organization.</p>`
        }
      </div>
    `;
  }
}
