import { LitElement, html, css } from 'lit';
import { auth } from '../../firebase-config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { customElement, property } from 'lit/decorators.js';

@customElement('manage-account')
export class ManageAccount extends LitElement {
  @property({ type: Object }) user = null;
  @property({ type: Object }) accountDetails = {
    name: 'John Doe',
    email: 'john.doe@example.com',
    password: '*******',
    organization: 'Example Org',
  };
  @property({ type: Object }) isEditing = {
    name: false,
    email: false,
    password: false,
    organization: false,
  };
  @property({ type: String }) profileImage = '';  // Stores the image URL

  static styles = css`
    .container {
      padding: 1em;
      max-width: 600px;
      margin: auto;
    }
    .profile-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5em;
    }
    .profile-image {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: #ccc;
      margin-bottom: 1em;
      background-size: cover;
      background-position: center;
      border: 3px solid #8e4953;
      position: relative;
      cursor: pointer;
    }
    .profile-image:hover .overlay {
      opacity: 1;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
    }
    .overlay i {
      color: white;
      font-size: 24px;
    }
    .upload-btn {
      display: none;
    }
    .upload-label {
      background-color: #8e4953;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      margin-top: 10px;
      text-align: center;
      display: inline-block;
    }
    .details-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1em;
      border: 1px solid #000;
      padding: 15px;
    }
    .label {
      font-weight: bold;
    }
    .value {
      margin-left: 1em;
      flex-grow: 1;
    }
    .edit-icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 1em;
    }
    .button {
      padding: 8px 16px;
      font-size: 16px;
      background-color: #8e4953;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 20px;
      margin-bottom: 10px;
    }
    .cancel-button {
      background-color: #8e4953;
    }
    .save-button {
      background-color: #8e4953;
    }
    input {
      width: 100%;
      padding: 8px;
      margin-left: 1em;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
    input[type="file"] {
      display: none;
    }
  `;

  constructor() {
    super();
    this.checkAuth();
  }

  checkAuth() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.requestUpdate();
      } else {
        this.user = null;
        this.requestUpdate();
      }
    });
  }

  toggleEdit(field) {
    this.isEditing = { ...this.isEditing, [field]: !this.isEditing[field] };
    this.requestUpdate();  // Request a re-render to reflect changes
  }

  handleInputChange(e, field) {
    this.accountDetails = { ...this.accountDetails, [field]: e.target.value };
  }

  handleImageUpload(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.profileImage = reader.result;  // Get the base64 image data
        this.requestUpdate();  // Update the component
      };
      reader.readAsDataURL(file);  // Read the file as Data URL
    }
  }

  cancel() {
    this.isEditing = { name: false, email: false, password: false, organization: false };
    this.requestUpdate();  // Request update to re-render
  }

  saveChanges() {
    this.isEditing = { name: false, email: false, password: false, organization: false };
    this.requestUpdate();  // Request update to re-render
  }

  async logout() {
    try {
      await signOut(auth);
      this.dispatchEvent(
        new CustomEvent('navigate-to-route', {
          detail: { routePath: '/' },
          bubbles: true,
          composed: true,
        })
      );
    } catch (error) {
      console.error('Sign out error', error);
    }
  }

  render() {
    return html`
      <div class="container">
        ${this.user
          ? html`
              <h2>Manage Account</h2>

              <div class="profile-section">
                <!-- Image Preview with Hover Overlay for Upload -->
                <div class="profile-image" style="background-image: url(${this.profileImage || '../assets/default-profile.png'});">
                  <div class="overlay" @click="${() => this.shadowRoot.querySelector('input[type=file]').click()}">
                    <i class="fa fa-camera"></i>
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  @change="${this.handleImageUpload}"
                  class="upload-btn"
                  id="imageUpload"
                />
                <label for="imageUpload" class="upload-label">Upload Image</label>
              </div>

              <!-- Name Row -->
              <div class="details-row">
                <span class="label">Name:</span>
                ${this.isEditing.name
                  ? html`
                      <input type="text" .value="${this.accountDetails.name}" @input="${(e) => this.handleInputChange(e, 'name')}" />
                    `
                  : html`
                      <span class="value">${this.accountDetails.name}</span>
                    `}
                <img src="../assets/edit.png" alt="Edit" class="edit-icon" @click="${() => this.toggleEdit('name')}" />
              </div>

              <!-- Email Row -->
              <div class="details-row">
                <span class="label">Email:</span>
                ${this.isEditing.email
                  ? html`
                      <input type="email" .value="${this.accountDetails.email}" @input="${(e) => this.handleInputChange(e, 'email')}" />
                    `
                  : html`
                      <span class="value">${this.accountDetails.email}</span>
                    `}
                <img src="../assets/edit.png" alt="Edit" class="edit-icon" @click="${() => this.toggleEdit('email')}" />
              </div>

              <!-- Password Row -->
              <div class="details-row">
                <span class="label">Password:</span>
                ${this.isEditing.password
                  ? html`
                      <input type="password" .value="${this.accountDetails.password}" @input="${(e) => this.handleInputChange(e, 'password')}" />
                    `
                  : html`
                      <span class="value">*******</span>
                    `}
                <img src="../assets/edit.png" alt="Edit" class="edit-icon" @click="${() => this.toggleEdit('password')}" />
              </div>

              <!-- Organization Row -->
              <div class="details-row">
                <span class="label">Organization:</span>
                ${this.isEditing.organization
                  ? html`
                      <input type="text" .value="${this.accountDetails.organization}" @input="${(e) => this.handleInputChange(e, 'organization')}" />
                    `
                  : html`
                      <span class="value">${this.accountDetails.organization}</span>
                    `}
                <img src="../assets/edit.png" alt="Edit" class="edit-icon" @click="${() => this.toggleEdit('organization')}" />
              </div>

              <!-- Action Buttons -->
              <div class="buttons">
                <button class="cancel-button button" @click="${this.cancel}">Cancel</button>
                <button class="save-button button" @click="${this.saveChanges}">Save Changes</button>
              </div>

              <!-- Logout Button -->
              <button class="button" @click="${this.logout}">Logout</button>
            `
          : html`<p>You are not logged in.</p>`}
      </div>
    `;
  }
}
