// header-component.js
import { LitElement, html, css } from "lit";
import { auth } from "../../firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";

export class HeaderComponent extends LitElement {
  static properties = {
    user: { type: Object },
  };

  static styles = css`
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:2px;
      color: white;
    }
    .logo {
      font-size: 1.5em;
      font-weight: bold;
      cursor: pointer;
    }
    .landingIMG {
      max-width:100%;
      height: auto;
      width:30%;
    }
    .auth-buttons {
      display: flex;
      gap: 1em;
    }
    button {
      padding: 2em;
      cursor: pointer;
      text-align: right;
      color: rgb(117, 91, 0);
    }
    button:hover {
      background-color: #555;
    }
    .welcome-text {
    padding:0.5em;
    color: #755b00;
    font-size:15px;
    }

    .login,
    .signup,
    .logout{
      padding:1em;
      cursor: pointer;
      text-align: right;
      color: #755b00;
      font-size:15px;
    }
       div.main {display:block;width:100%;max-width:600px;margin:0px auto;}


  @media (max-width: 768px) {
   .landingIMG {
      max-width:100%;
      height: auto;
      width:100%;
    }

    
}
  `;

  constructor() {
    super();
    this.user = null;

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.requestUpdate();
        
      } else {
        this.user = null;
        console.log("this.usernull"+this.user);
      }

    
    });


  }

  login() {
    // Add your login logic here
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/login" },
        bubbles: true,
        composed: true,
      })
    );
  }

  logo() {
    // Add your login logic here
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/" },
        bubbles: true,
        composed: true,
      })
    );
  }

 



  signup() {
    // Add your login logic here
    // Router.go("/signup");
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/signup" },
        bubbles: true,
        composed: true,
      })
    );
  }

  logout() {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        this.dispatchEvent(
          new CustomEvent("navigate-to-route", {
            detail: { routePath: "/" },
            bubbles: true,
            composed: true,
          })
        );
        
      })
      .catch((error) => {
        console.error("Sign out error", error);
      });
  }

  render() {
    return html`
      <header>
        <div class="logo">
          <img
            @click=${this.logo}
            class="landingIMG"
            src="../assets/logo.png"
            alt="Centered Image"
          />
        </div>
        <div class="auth-buttons">
          ${this.user
            ? html`
                <span class="welcome-text">Welcome, ${this.user.email}</span>
                 <dropdown-menu .details="${this.user}"></dropdown-menu>
                
              `
            : html`
                <span class="signup" @click=${this.signup}>Sign Up</span>
                <span class="login" @click=${this.login}>Log In</span>
              `}
        </div>
      </header>
    `;
  }
}

customElements.define("header-component", HeaderComponent);
